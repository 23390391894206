<template>
  <FocusTrap>
    <div class="card" id="mycard">

      <div class="page-header page-header-light" >
        <div class="page-header-content header-elements-lg-inline" >
          <div class="page-title d-flex" style="padding: 9px;">
            <h6>
              <i class="icon-list mr-2"></i> <span class="font-weight-semibold">Employee's </span> - List
            </h6>
            <a href="#" class="header-elements-toggle text-body d-lg-none"><i class="icon-more"></i></a>
          </div>

          <div class="header-elements d-none">
            <form action="#">
              <div class="form-group">

                <div class="input-group input-group-feedback input-group-feedback-right wmin-lg-200">
                  <input id="txtsearch" type="text" class="form-control wmin-lg-200" placeholder="Search" autocomplete="off" style="padding-left: 20px;">
                  <div class="form-control-feedback">
                    <i class="icon-search4"></i>
                  </div>

                  <div class="input-group-append position-static">
                    <button type="button" class="btn btn-outline-secondary btn-icon" >
                      <i class="icon-gear"></i>
                    </button>
                  </div>

                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="table-responsive">
        <table id="emp-table"
               class="table  table-hover table-bordered table-condensed table-columned"
               data-search="false"
               data-pagination="true"
               data-show-refresh="false"
               data-show-columns="false"
               data-page-list="[10, 25, 50, 100, ALL]"
               data-show-footer="false"
               data-toggle="context"
               data-target=".context-table">
          <thead>
          <tr>
            <th data-field="id" data-class="d-none">id</th>
            <th data-formatter="runningFormatter" data-width="100">S.No</th>
            <th data-field="id" data-class="d-none">Code</th>
            <th data-field="code" data-width="100" data-sorting="true" >Emp Code</th>
            <th data-field="name" >Name</th>
            <th data-field="designation.name" >Designation</th>
          </tr>
          </thead>
          <tbody>
          </tbody>
          <tfoot>
          </tfoot>
        </table>
      </div>

      <!-- Context Menu -->
      <div id="context-menu" class="context-table">
        <div class="dropdown-menu">
          <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: darkgreen"></i>Refresh</a>
          <!--<div class="dropdown-divider"></div>-->
          <!--<a href="#" class="dropdown-item"><i class="icon-cross2 text-danger"></i>Discard</a>-->
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item"> <i class="icon-pencil3" ></i>Modify</a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item"><i class="icon-blocked text-danger"></i>Remove</a>
        </div>
      </div>
      <!-- End of Context Menu -->

      <!-- Clickable menu -->
      <ul class="fab-menu fab-menu-fixed fab-menu-bottom-right is_stuck" data-fab-toggle="hover" >
        <li>
          <a class="fab-menu-btn btn bg-primary-600 btn-float rounded-round btn-icon" @click="showModal">
            <i class="fab-icon-open icon-plus3"></i>
            <i class="fab-icon-close icon-plus3"></i>
          </a>
        </li>
      </ul>
      <!-- /clickable menu -->

      <vmodal name="emp_window" transition="nice-modal-fade" :delay="100" :resizable="true" :width="600" :height="260" :draggable="true"  @before-open="beforeOpen" @before-close="beforeClose" >
        <EmployeeForm  v-bind:myemp="emp" v-on:employee_saved="loadData" v-on:employee_window_closed="closeModal" />
      </vmodal>

    </div>
  </FocusTrap>
</template>

<script>
  import EmployeeForm from '@/views/hr/employee/EmployeeForm.vue'
  import FabButton from '@/components/core/FabButton.vue'
  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  import { store } from '@/store/store.js'

  export default {
    name: 'EmployeeView',
    store,
    components: {
      EmployeeForm,
      FabButton
    },
    data () {
      return {
        mytable: {},
        emp: JSON.parse('{"id":0,"status":"Active","name":"","design_id":1,"extrusion":false,"stentering":false,"warping":false,"designation":{"id":1,"status":"Active","name":"","min_wage":0}}')
      }
    },
    created () {

    },
    mounted () {
      const self = this;

      this.$data.mytable = $('#emp-table');

      this.$data.mytable.bootstrapTable();
      $('[data-toggle="popover"]').popover();

      this.$data.mytable.contextmenu({
        target: '#context-menu',
        scopes: 'tbody > tr',
        onItem: function (row, e) {
          let id = $(row.children('*')[0]).text();

          if ($(e.target).text() === 'Modify') {
            self.modifyDocument (id);
          } else if ($(e.target).text() === 'Refresh') {
            self.loadData();
          } else if ($(e.target).text() === 'Remove') {
            self.removeDocument(id);
          }
        }
      });

      $('#txtsearch').keyup(function () {
        let val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase();
        $('#emp-table>tbody>tr').show().filter(function () {
          let text = $(this).text().replace(/\s+/g, ' ').toLowerCase();
          return !~text.indexOf(val);
        }).hide();
      });

      self.loadData();
    },
    methods: {
      closeModal () {
        try {
          this.$modal.hide('emp_window');
          this.$data.emp = JSON.parse('{"id":0,"status":"Active","name":"","design_id":1,"extrusion":false,"stentering":false,"warping":false,"designation":{"id":1,"status":"Active","name":"","min_wage":0}}');
        }catch (e) {
          alert(e);
        }
      },
      showModal () {
        this.$modal.show('emp_window');
      },
      afterClose(){
        this.$data.emp = JSON.parse('{"id":0,"status":"Active","name":"","design_id":1,"extrusion":false,"stentering":false,"warping":false,"designation":{"id":1,"status":"Active","name":"","min_wage":0}}');
      },
      loadData () {
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        this.$modal.hide('emp_window');

        $(self.$data.mytable).block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        self.$data.mytable.bootstrapTable('load',[]);

        fetch(`${process.env.VUE_APP_ROOT_API}api/hr/employees/active/after/1`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.ok) {
            if( _.isArray(resp.data) ){
              self.$data.mytable.bootstrapTable('load', resp.data);
            }
          } else {
            swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        }).finally(function () {
          $(self.$data.mytable).unblock();
        })

      },
      modifyDocument (id) {
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        self.$data.emp = JSON.parse('{"id":0,"status":"Active","name":"","design_id":1,"extrusion":false,"stentering":false,"warping":false,"designation":{"id":1,"status":"Active","name":"","min_wage":0}}');

        fetch(`${process.env.VUE_APP_ROOT_API}api/hr/employee/${id}`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.ok) {

            self.$data.emp = resp.data;
            self.$modal.show('emp_window');
          } else {
            swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text:  err.toString() , type:  "error"} );
        });
      },
      removeDocument (id) {
        const self = this;

        self.$data.emp.id = id;

        const requestOptions = {
          method: 'DELETE',
          mode:'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.emp)
        };

        swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          timer: 3000,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete tms!'
        }).then((result) => {
          if (result.value) {
            fetch(`${process.env.VUE_APP_ROOT_API}api/hr/employee`,requestOptions).then(userService.handleResponse).then(function (resp) {
              self.$modal.hide('emp_window')
              self.loadData();
              swal(
                'Deleted!',
                'Your request has been processed',
                'success'
              );
            }).catch(function (err) {
              swal({ title: "Oops", text: err.toString(), type: "error" });
            });
          }
        });
      },
    }
  }
</script>

<style scoped>

</style>
